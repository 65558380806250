import Graphql from '@/services/Graphql'
import Logger from '@/services/Logger'
import { graphqlManageError } from '@/helpers'

class Cards {
  list ({ token, storeViewCode }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'cardList',
      null, // variable
      false) // use cache
      .then(resp => {
        if (graphqlManageError(resp)) {
          // eslint-disable-next-line
          throw { body: { result: graphqlManageError(resp) } }
        }
        return resp && resp.data ? resp.data.cardList : []
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  disable ({ token, storeViewCode, recurringDetailReference }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'cardDisable',
      {
        recurringDetailReference
      },
      false) // use cache
      .then(resp => {
        if (graphqlManageError(resp)) {
          // eslint-disable-next-line
          throw { body: { result: graphqlManageError(resp) } }
        }
        if (resp && resp.data && resp.data.cardDisable) {
          return resp.data.cardDisable
        } else {
          return false
        }
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }
}

const classSingleton = new Cards()

export default classSingleton
