var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"text-left",class:[_vm.isValidForm ? 'is-valid-form' : 'is-invalid-form'],attrs:{"novalidate":""}},[(_vm.useOtherAddressLabel)?_c('b-form-group',{attrs:{"id":"groupUseOtherAddress"}},[_c('base-check-box',{attrs:{"name":this.name + '-useotheraddress',"label":_vm.useOtherAddressLabel},model:{value:(_vm.address.useotheraddress),callback:function ($$v) {_vm.$set(_vm.address, "useotheraddress", $$v)},expression:"address.useotheraddress"}})],1):_vm._e(),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddress),expression:"showAddress"}],staticClass:"single-address"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"id":"groupFirstName","label":_vm.$t('account.FirstName')+'*',"label-for":this.name + '-firstname'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":this.name + '-firstname',"autocomplete":"firstname","type":"text","focus":true,"validations":[
              {
                condition: _vm.errors.has(this.name + '-firstname'),
                text: _vm.errors.first(this.name + '-firstname')
              }
            ]},model:{value:(_vm.address.firstname),callback:function ($$v) {_vm.$set(_vm.address, "firstname", $$v)},expression:"address.firstname"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"id":"groupLastName","label":_vm.$t('account.LastName')+'*',"label-for":this.name + '-lastname'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":this.name + '-lastname',"autocomplete":"lastname","type":"text","validations":[
              {
                condition: _vm.errors.has(this.name + '-lastname'),
                text: _vm.errors.first(this.name + '-lastname')
              }
            ]},model:{value:(_vm.address.lastname),callback:function ($$v) {_vm.$set(_vm.address, "lastname", $$v)},expression:"address.lastname"}})],1)],1)],1),_c('address-autocomplete',{ref:"addressAutocomplete",attrs:{"name":this.name,"use-email":_vm.useEmail,"email":_vm.email,"address":_vm.address},on:{"update:email":_vm.emailAvailability,"changeCountry":function($event){return _vm.$emit('changeCountry', $event)}}})],1),(_vm.isBilling)?[_c('div',{staticClass:"checkboxes"},[_c('CreateUser',{ref:"createUser",attrs:{"emailExist":_vm.emailExist}}),(_vm.enableReqInvoice)?_c('b-form-group',{attrs:{"id":"groupRequireInvoice","label-for":_vm.name + '-requireInvoice'}},[_c('base-check-box',{attrs:{"disabled":_vm.requiredReqInvoice,"name":_vm.name + '-requireInvoice',"use_prevent":false,"checkboxClass":"big","label":_vm.$t('address.RequireInvoice')},model:{value:(_vm.requireInvoice),callback:function ($$v) {_vm.requireInvoice=$$v},expression:"requireInvoice"}})],1):_vm._e(),(_vm.requireInvoice)?[_c('b-form-group',{attrs:{"id":"groupTaxVat","label-for":_vm.name + '-taxid'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|fiscalCode|VAT'),expression:"'required|fiscalCode|VAT'"}],attrs:{"name":_vm.name + '-taxid',"autocomplete":"taxid","type":"text","placeholder":((_vm.$t('address.TaxId_VatId')) + " *"),"validations":[
              {
                condition: _vm.errors.has(_vm.name + '-taxid'),
                text: _vm.errors.first(_vm.name + '-taxid')
              }
            ]},model:{value:(_vm.address.vat_id),callback:function ($$v) {_vm.$set(_vm.address, "vat_id", $$v)},expression:"address.vat_id"}})],1)]:_vm._e()],2)]:_vm._e(),(_vm.showSubmitButton)?_c('b-button',{attrs:{"type":"button","variant":"primary","disabled":!_vm.isValidForm,"block":true},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('address.Continue')))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }