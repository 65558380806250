<template>
  <b-form-radio value="scalapay" @change="getScalapayLink">
    <div class="label-left">
      <span>{{ $t('Scalapay') }}</span>
      <img :src="require('theme/assets/image/scalapay-logo.svg')" class="img-fluid" width="80" />
    </div>
  </b-form-radio>
</template>

<script>
export default {
  name: 'PaymentMethodScalapay',
  computed: {
    cartId () {
      return this.$store.state.cart.cartId || false
    },
    isLogged () {
      return this.$store.getters['user/isLogged']
    }
  },
  methods: {
    getScalapayLink () {
      if (this.cartId) {
        this.$store.commit('ui/showLoader')
        let payload = {
          quote_id: this.cartId,
          is_guest_cart: !this.isLogged
        }
        this.$store.dispatch('pl_scalapay/getScalapayLink', payload)
          .then((link) => {
            return this.$emit('scalapayLink', link)
          })
          .finally(() => {
            this.$store.commit('ui/hideLoader')
          })
      }
    }
  }
}
</script>
